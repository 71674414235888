<!--
--------------------------------------------------------------------------------
<copyright file="SalesMap.vue" company="Bystronic Laser AG">
 Copyright (C) Bystronic Laser AG 2021-2024
</copyright>
--------------------------------------------------------------------------------
-->

<template>
  <section>
    <details>
      <summary>{{ $t('widget.map_sales') }}</summary>
      <p>{{ $t('widget_info_text.map_sales_info_text') }}</p>
      <p class="tip list-item tip-bkg">{{ $t('widget_info_text.tip') }}:</p>
      <ul class="list tip-bkg">
        <li class="list-item2">{{ $t('widget_info_text.map_no_data_tip') }}</li>
      </ul>
    </details>
    <details>
      <summary>{{ $t('widget_info_text.zoom') }}</summary>
      <p>{{ $t('widget_info_text.map_zoom') }}</p>
      <ul class="list">
        <li class="list-item2">{{ $t('widget_info_text.map_zoom_dbl_click') }}</li>
        <li class="list-item2">{{ $t('widget_info_text.map_zoom_scroll') }}</li>
        <li class="list-item2">{{ $t('widget_info_text.map_zoom_buttons') }}</li>
      </ul>
    </details>
    <details>
      <summary>{{ $t('widget_info_text.tooltip') }}</summary>
      <p>{{ $t('widget_info_text.map_sales_tooltip') }}</p>
    </details>
  </section>
</template>

<script>
export default {};
</script>

<style></style>
